import React, { useEffect } from "react";
import "./Footer.css";
import { FaFacebookF, FaMailBulk } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { FaTwitter } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="App">
      <footer className="footer">
        <div className="footer-content">
            <Link to="/" className="footer-logo">
            <img
              className="footer-logo"
             /* src="\images\biscoza_border3.png"*/
             src="\images\footerlogo.webp"
              alt="Biscoza"
            ></img>
          </Link>
          
          <h3 className="footerTitle">
            {/*Follow Us On Our Social Media Platforms*/}
            تابعونا على حساباتنا على منصات التواصل الاجتماعي
          </h3>

          <ul className="socials">
            <li>
              <a href="https://www.facebook.com/Biscoza" target="blank">
                <FaFacebookF className="footer-icons" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/bisco.za/" target="blank">
                <FaInstagram className="footer-icons" />
              </a>
            </li>
            <li>
              <a href="mailto:info@biscoza.co" target="blank">
              <MdOutlineEmail className="footer-icons" />
              </a>
            </li>
            <li>
              <a href="https://wa.me/+963944024343" target="blank">
                <FaWhatsapp className="footer-icons" />
              </a>
            </li>
          </ul>
          <hr className="solid"></hr>
          <div className="footerBottom">
            <p>{/*All Rights Preserved Biscoza © 2024*/}
            جميع الحقوق محفوظة بيسكوزا © 2024

            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
