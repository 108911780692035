import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
  Autoplay,
  EffectCoverflow,
  Navigation,
  Pagination,
} from "swiper/modules";
import Aos from "aos";
import "aos/dist/aos.css";
import "./ProductsDetails.css";
import Loading from "../Loading/Loading";

function ProductsDetails() {
  const [isLoading, setIsLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const { ProductId } = useParams();

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const { ProductsData } = await import("../../Data/ProductsData");
        const selectedProduct = ProductsData[ProductId];
        if (selectedProduct) {
          // Preload all product images
          await Promise.all(
            selectedProduct.Images.map((src) => {
              return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = src;
                img.onload = resolve;
                img.onerror = reject;
              });
            })
          );
          setProduct(selectedProduct);
        } else {
          console.error("Invalid ProductId:", ProductId);
        }
      } catch (error) {
        console.error("Error loading product data:", error);
      } finally {
        setIsLoading(false); // Mark as loaded regardless of success or failure
      }
    };

    Aos.init({ duration: 2000 });
    window.scrollTo(0, 0);
    fetchProductData();
  }, [ProductId]);

  if (isLoading) {
    return <Loading />;
  }

  if (!product) {
    return <div>Product not found!</div>;
  }

  return (
    <div className="hugy-body" dir="ltr">
      <div
        className="border-container"
        data-aos="fade-left"
        data-aos-duration="1000"
      >
        <h1 data-aos="fade-up" data-aos-duration="2700" className="gg">
          {product.title}
        </h1>
        <Swiper
          className="pd-swiper"
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          }}
          navigation={true}
          autoplay={{
            delay: 3000,
          }}
          rewind={true}
          spaceBetween={10}
          pagination={{
            clickable: true,
            type: "bullets",
          }}
          modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
        >
          {product.Images.slice(1).map((image, index) => (
            <SwiperSlide key={index} className="pd-swiper-slide">
              <img src={image} alt={`Product Image ${index + 1}`} loading="lazy" />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="background-hugy" dir="rtl">
          <section className="products-details-section">
            <p
              className="products-details-text-title"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              وصف المنتج
            </p>
            <p
              className="products-details-text-info"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              {product.description}
            </p>
            <p
              className="products-details-text-title"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              مكونات المنتج
            </p>
            <p
              className="products-details-text-info"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              {product.ingredients}
            </p>
            <p
              className="products-details-text-title"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              الوزن
            </p>
            <p
              className="products-details-text-info"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              {product.weight}
            </p>
            <p
              className="products-details-text-title"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              التعبئة
            </p>
            <p
              className="products-details-text-info"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              {product.packing}
            </p>
          </section>
        </div>
      </div>
    </div>
  );
}

export default ProductsDetails;

/*import React from "react";
import { useQuery } from "@tanstack/react-query";
import Loading from "../Loading/Loading.jsx";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./ProductsDetails.css";
import {
  Autoplay,
  EffectCoverflow,
  Navigation,
  Pagination,
} from "swiper/modules";
import { useParams } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";



function ProductsDetails({ images }) {
  useEffect(() => {
    Aos.init({ duration: 2000 });
    window.scrollTo(0, 0);
  }, []);
  
  const { ProductId } = useParams();

  return (
    <>
    <div  className="hugy-body" dir="ltr">
      
      <div
      className="border-container"
      data-aos="fade-left"
      data-aos-duration="1000"
    >
      <h1 data-aos="fade-up" data-aos-duration="2700" className="gg">
      {images[ProductId].title}
      </h1>
      <Swiper
  className="pd-swiper"
  effect={"coverflow"}
  grabCursor={true}
  centeredSlides={true}
  slidesPerView={"auto"}
  coverflowEffect={{
    rotate: 50,
    stretch: 0,
    depth: 100,
    modifier: 1,
    slideShadows: false,
  }}
  navigation={true}
  autoplay={{
    delay: 3000,
  }}
  rewind={true}
  spaceBetween={10}
  pagination={{
    clickable: true,
    type: "bullets",
  }}
  modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
>

  {images[ProductId].Images.slice(1).map((image, index) => {
    return (
      <SwiperSlide key={index}  className="pd-swiper-slide">
        <img src={image} alt={`Product Image ${index + 1}`} />
      </SwiperSlide>
    );
  })}
</Swiper>
      <div className="background-hugy" dir="rtl">
      <section className="products-details-section">
        <p
          className="products-details-text-title"
          data-aos="fade-up"
          data-aos-duration="2000"
        >

          وصف المنتج
        </p>
        <p
          className="products-details-text-info"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
           {images[ProductId].description}
        </p>
        <p
          className="products-details-text-title"
          data-aos="fade-up"
          data-aos-duration="2000"
        >

          مكونات المنتج
        </p>
        <p
          className="products-details-text-info"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          {images[ProductId].ingredients}
        </p>
        <p
          className="products-details-text-title"
          data-aos="fade-up"
          data-aos-duration="2000"
        >

          الوزن
        </p>
        <p
          className="products-details-text-info"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          {images[ProductId].weight}
        </p>
        <p
          className="products-details-text-title"
          data-aos="fade-up"
          data-aos-duration="2000"
        >

التعبئة
        </p>
        <p
          className="products-details-text-info"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          {images[ProductId].packing}
        </p>
      </section>
      </div>
    </div>
 
    </div>
   </>
  );
}

export default ProductsDetails;
*/

