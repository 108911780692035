import React, { useEffect, useState } from "react";
import "./HomePage.css";
import Main from "../../Components/Main/Main";
import Home from "../../Components/Home/Home";
import Loading from "../Loading/Loading";


function HomePage() {
  const [loading, setLoading] = useState(true); // Track loading state
  const [homeSliderData, setHomeSliderData] = useState(null); // Store HomeSlider data
  const [productsData, setProductsData] = useState(null); // Store ProductsData

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      try {
        // Dynamically import both data files
        const { HomeSlider } = await import("../../Data/SliderImages");
        const { ProductsData } = await import("../../Data/ProductsData");

        // Set data to state
        setHomeSliderData(HomeSlider);
        setProductsData(ProductsData);
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        setLoading(false); // Set loading to false once data is loaded
      }
    };

    fetchData(); // Trigger data fetching
  }, []);

  if (loading) {
    return <Loading />; // Show loading indicator until data is ready
  }

  return (
    <div className="background">
      {/* Pass loaded data as props to child components */}
      <Home slides={homeSliderData} />
      <Main images={productsData} />
    </div>
  );
}

export default HomePage;



/*import React, { useEffect } from "react";
import "./HomePage.css";
import Main from "../../Components/Main/Main";
import Home from "../../Components/Home/Home";
import { ProductsData } from "../../Data/ProductsData";
import { HomeSlider } from "../../Data/SliderImages";

function HomePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="background">
      <Home slides={HomeSlider}/>
      <Main images={ProductsData} />
    </div>
  );
}

export default HomePage;*/